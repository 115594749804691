<template>
    <div
        id="container"
        class="card card-f-h-local relative flex flex-col"
        :class="view ? 'w-full' : 'w-10'"
    >
        <StatusBar
            v-show="!detailsCollapsed"
            @statusChanged="statusChanged"
        />

        <div
            v-show="detailsCollapsed"
            id="btn-collapse"
            class="rounded-full bg-red-500 absolute w-10 h-10 flex justify-center items-center top-0 right-0 z-50 mr-4
            mt-4 hover:bg-red-600 cursor-pointer"
            @click="$store.commit('economy/toggleDetailsView', false)"
        >
            <svg fill="currentColor" viewBox="0 0 20 20" class="w-4 h-4 text-white">
                <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1
                    1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                />
            </svg>
        </div>

        <el-table
            v-if="invoicesData.length > 0"
            id="table"
            :data="invoicesData"
            :default-sort="{prop: 'id', order: 'descending'}"
            :show-summary="!detailsCollapsed"
            :summary-method="getSummaries"
            style="width: 100%"
            highlight-current-row
            @selection-change="handleSelectionChange"
        >
            <el-table-column prop="custom_nr" sortable :label="$t('economy.nr')" width="60" />
            <el-table-column v-if="detailsCollapsed" :label="$t('economy.information')">
                <template slot-scope="scope">
                    <div class="cursor-pointer" @click="handleChangeDetails(scope.row.uuid)">
                        <p>
                            {{ scope.row.client }}
                        </p>
                        <div class="flex justify-between">
                            <p>
                                {{ intToMoneyFormat(scope.row.items_sum) }}
                            </p>
                            <p>
                                {{ $t(`economy.${scope.row.status}`) }}
                            </p>
                        </div>
                        <div class="flex justify-between">
                            <p>
                                {{ scope.row.issue_at }}
                            </p>
                            <p class="flex items-center">
                                <svg fill="currentColor" viewBox="0 0 20 20" class="w-4 h-4 text-gray-500">
                                    <path
                                        fill-rule="evenodd"
                                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586
                                        11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </p>
                            <p>
                                {{ scope.row.due_date_at }}
                            </p>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column v-if="!detailsCollapsed" prop="client" sortable :label="$t('economy.client')" />
            <el-table-column
                v-if="!detailsCollapsed"
                prop="status"
                sortable
                :sort-method="sortAmount"
                :label="$t('economy.amount')"
                width="110"
                align="right"
            >
                <template slot-scope="scope">
                    {{ intToMoneyFormat(scope.row.items_sum) }}
                </template>
            </el-table-column>
            <el-table-column v-if="!detailsCollapsed" prop="status" sortable :label="$t('economy.status')" width="130" align="center">
                <template slot-scope="scope">
                    {{ $t(`economy.${scope.row.status}`) }}
                </template>
            </el-table-column>
            <el-table-column v-if="!detailsCollapsed" prop="issue_at" :label="$t('economy.issue_at')" width="120" align="center" />
            <el-table-column v-if="!detailsCollapsed" prop="due_date_at" :label="$t('economy.due_date')" width="120" align="center" />
            <el-table-column v-if="!detailsCollapsed" width="50">
                <template slot-scope="scope">
                    <!-- <router-link
                        :to="'/economy/invoices/'+ scope.row.id"
                        class="el-button fas fa-search el-tooltip item el-button--primary el-button--mini"
                    /> -->
                    <el-button type="primary" size="mini" :title="$t('common.details')" :disabled="!userCan('update invoices')" @click="handleDetails(scope.row.uuid)">
                        <fa-icon :icon="['far', 'edit']" />
                    </el-button>
                    <!-- <a @click.prevent="handleDetails(scope.row.uuid)" :href="`/invoices/${scope.row.uuid}`">
                        <fa-icon :icon="['far', 'edit']" />
                    </a> -->
                </template>
            </el-table-column>
            <el-table-column
                v-if="selectedStatus !== ''"
                type="selection"
                width="45"
            />
        </el-table>

        <InitialLoader v-if="$wait.is('loading.initial_*')" />

        <NoDataInformation :data="invoicesData.length" waitKey="loading" />
    </div>
</template>
<script>
import sumBy from 'lodash/sumBy';
import queryString from 'query-string';

export default {
    components: {
        StatusBar: () => import(/* webpackChunkName: "InvoicesTable/StatusBar" */ './StatusBar'),
    },

    props: {
        invoicesData: {
            type:    Array,
            default: () => [],
        },
    },

    data() {
        return {
            selectedStatus:   '',
            selectedInvoices: [],
            view:             true,
        };
    },

    computed: {
        detailsCollapsed() {
            return this.$store.state.economy.detailsView;
        },
    },

    watch: {
        // @Filip
        detailsCollapsed() {
            if (!this.detailsCollapsed) {
                return this.expand();
            }
        },
    },

    created() {
        const query = queryString.parse(window.location.search, { sort: false });
        this.selectedStatus = query.status ? query.status : '';
    },

    methods: {
        handleDetails(uuid) {
            const query = queryString.parse(window.location.search, { sort: false });
            query.invoice_uuid = uuid;

            this.$router.replace({
                query,
            }).catch(() => {});

            this.collapse(uuid);
        },

        handleChangeDetails(uuid) {
            this.$emit('showInvoiceDetails', uuid);
        },

        getSummaries() {
            const sum = sumBy(this.invoicesData, o => o.items_sum);
            const sums = [];
            sums[2] = this.intToMoneyFormat(sum);
            return sums;
        },

        sortAmount(a, b) {
            return parseFloat(a.items_sum) - parseFloat(b.items_sum);
        },

        handleSelectionChange(val) {
            this.$store.commit('economy/updateSelectedInvoices', val);
        },

        expand() {
            this.$emit('toggleDetails');
            this.view = true;
        },

        collapse(uuid) {
            this.$store.commit('economy/toggleDetailsView', true);
            this.$emit('toggleDetails', uuid);
            this.view = false;
        },

        statusChanged(status) {
            this.selectedStatus = status;
            this.$emit('refresh');
        },
    },
};
</script>
<style>
.card-f-h-local {
    min-width: 320px;
    min-height: calc(100vh - 166px);
}
</style>
